import { useEffect } from "react";
import MintConnect from "./sections/MintConnect";

const App = () => {
  const pageLoaded = () => {
    const ele = document.getElementById("loader");
    if (ele) {
      ele.classList.add("available");
      setTimeout(() => {
        ele.outerHTML = "";
      }, 2000);
    }
  };

  useEffect(() => {
    window.addEventListener("load", pageLoaded);
    return () => window.removeEventListener("load", pageLoaded);
  }, []);

  return <MintConnect />;
};

export default App;
